<template>
  <v-container
    fluid
    fill-height
    class="pa-3 align-start justify-start background"
  >
    <v-card
      style="width: 100%; height: 100%"
    >
      <v-toolbar
        flat
        rounded
        dense
        color="white"
      >
        <v-toolbar-title>
          {{ $t('profile|profile') }}
        </v-toolbar-title>
      </v-toolbar>
      <v-form
        v-if="professional.accountData"
        class="mt-10 px-8"
      >
        <v-text-field
          v-model="professional.accountType"
          outlined
          dense
          readonly
          :label="capitalizeFirstLetter($t('common|type'))"
        />
        <v-text-field
          v-model="professional.accountData.firstName"
          outlined
          dense
          readonly
          :label="$t('clients|first_name')"
        />
        <v-text-field
          v-model="professional.accountData.lastName"
          outlined
          dense
          readonly
          :label="$t('clients|surname')"
        />
        <v-text-field
          v-model="professional.accountData.phone"
          outlined
          dense
          readonly
          :label="$t('profile|phone')"
        />
        <v-text-field
          v-model="professional.accountData.email"
          outlined
          dense
          readonly
          :label="$t('common|email')"
        />
        <v-text-field
          v-model="professional.accountData.address"
          outlined
          dense
          readonly
          :label="$t('clients|address')"
        />
        <v-text-field
          v-model="professional.accountData.city"
          outlined
          dense
          readonly
          :label="$t('clients|city')"
        />
      </v-form>

      <v-form
        v-if="professional.companyData"
        class="mt-10 px-8"
      >
        <v-text-field
          outlined
          dense
          readonly
          :placeholder="$t('clients|company')"
          persistent-placeholder
          :label="capitalizeFirstLetter($t('common|type'))"
        />
        <v-text-field
          v-model="professional.companyData.companyName"
          outlined
          dense
          readonly
          :label="$t('common|name')"
        />
        <v-text-field
          v-model="professional.companyData.phone"
          outlined
          dense
          readonly
          :label="$t('profile|phone')"
        />
        <v-text-field
          v-model="professional.companyData.email"
          outlined
          dense
          readonly
          :label="$t('common|email')"
        />
        <v-text-field
          v-model="professional.companyData.address"
          outlined
          dense
          readonly
          :label="$t('clients|address')"
        />
        <v-text-field
          v-model="professional.companyData.city"
          outlined
          dense
          readonly
          :label="$t('clients|city')"
        />
        <v-text-field
          v-model="professional.companyData.representativeName"
          outlined
          dense
          readonly
          :label="$t('mixed|representative_name')"
        />
        <v-text-field
          v-model="professional.companyData.representativeEmail"
          outlined
          dense
          readonly
          :label="$t('mixed|representative_email')"
        />
      </v-form>

      <v-form
        v-if="professional.groupName"
        class="mt-10 px-8"
      >
        <v-text-field
          outlined
          dense
          readonly
          placeholder="Company department/group"
          persistent-placeholder
          :label="capitalizeFirstLetter($t('common|type'))"
        />
        <v-text-field
          v-model="professional.groupName"
          outlined
          dense
          readonly
          :label="$t('common|name')"
        />
        <v-text-field
          v-model="professional.belongsToCompany.companyData.phone"
          outlined
          dense
          readonly
          :label="$t('profile|phone')"
        />
        <v-text-field
          v-model="professional.groupEmail"
          outlined
          dense
          readonly
          :label="$t('common|email')"
        />
        <v-text-field
          v-model="professional.belongsToCompany.companyData.address"
          outlined
          dense
          readonly
          :label="$t('clients|address')"
        />
        <v-text-field
          v-model="professional.belongsToCompany.companyData.city"
          outlined
          dense
          readonly
          :label="$t('clients|city')"
        />
        <v-text-field
          v-model="professional.belongsToCompany.companyData.representativeName"
          outlined
          dense
          readonly
          :label="$t('mixed|representative_name')"
        />
        <v-text-field
          v-model="professional.belongsToCompany.companyData.representativeEmail"
          outlined
          dense
          readonly
          :label="$t('mixed|representative_email')"
        />
      </v-form>

      <v-btn
        rounded
        small
        color="primary"
        min-width="150"
        style="position: absolute; bottom: 15px; left: 25px;"
        link
        to="/professionals"
      >
        <v-icon
          small
          class="ml-n2 mr-2"
        >
          mdi-undo
        </v-icon>
        {{ $t('actions|back') }}
      </v-btn>
    </v-card>
  </v-container>
</template>

<script>
import { mapState, mapActions } from 'vuex'
import { mdiTrashCanOutline, mdiAccountTie } from '@mdi/js'

export default {
  data () {
    return {
      icons: {
        trash: mdiTrashCanOutline,
        tie: mdiAccountTie
      }
    }
  },
  computed: {
    ...mapState({
      account: state => state.account.account
    }),
    professional () {
      const actualProfessional = this.account.myProfessionals.accounts.find(acc => acc._id === this.$route.params._id) ||
      this.account.myProfessionals.company.find(c => c._id === this.$route.params._id) ||
      this.account.myProfessionals.groups.find(gr => gr._id === this.$route.params._id)
      return actualProfessional
    }
  },
  watch: {
    professional (value) {
      if (!value) this.$router.push('/professionals')
    }
  },
  methods: {
    ...mapActions({
      addToast: 'toasts/addToast',
      deleteProfessional: 'account/deleteProfessional'
    }),
    capitalizeFirstLetter (text) {
      text = text.charAt(0).toUpperCase() + text.slice(1)
      return text
    },
    async deleteThisProfessional (professional) {
      const res = await this.$dialog.confirm({
        title: this.$t('common|warning'),
        text: this.$t('common|are_you_sure_you_want_to_delete_this_resource')
      })
      if (!res) return
      if (res) {
        const _id = professional._id
        let payload
        if (professional.accountData) {
          payload = {
            deleteIdFromAccountsArr: true
          }
        } else if (professional.companyData) {
          payload = {
            deleteIdFromCompanyArr: true
          }
        } else if (professional.groupName) {
          payload = {
            deleteIdFromGroupsArr: true
          }
        }
        try {
          await this.deleteProfessional({ _id: _id, payload })
          this.addToast({
            title: this.$t('message|delete_professional_success'),
            snackbarColor: 'success',
            color: 'white'
          })
        } catch (error) {
          console.error(error, 'error')
          this.addToast({
            title: this.$t('message|delete_professional_fail'),
            snackbarColor: 'error',
            color: 'white'
          })
        }
      }
    }
  }
}
</script>

<style>
.v-text-field-bussines-card > .v-input__control > .v-input__slot:before {
  border-style: none;
}
.v-offset {
  margin: 0 auto;
  max-width: calc(100% - 32px);
  position: relative;
}
.document-list {
  display: flex;
  flex-direction: column;
  height: 100%;
}
.document-list-container {
  flex-grow: 1;
  overflow-y: auto;
}
.icon {
  position: absolute;
  left: 0;
}
.grow:hover {
  transform: scale(1.1);
}
</style>
